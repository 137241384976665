import './socialButtons.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInstagram, faWhatsapp, faLinkedinIn} from "@fortawesome/free-brands-svg-icons";
import {telephoneNumber} from "../assets/constants.js"

export const SocialButtons = () => {

    //telephoneNumber with only numbers
    const telephoneNumberOnlyNumbers = telephoneNumber.replace(/\D/g, '');
    const message = `Hola, me gustaria saber mas sobre sus servicios`;
    //message as urlencode
    const messageUrlEncode = encodeURIComponent(message);

    return <div className="section">
        <div>
            <a className={`iconWhatsapp circleLink`}
               href={`https://wa.me/${telephoneNumberOnlyNumbers}?text=${messageUrlEncode}`} target="_blank">
                <FontAwesomeIcon icon={faWhatsapp}/>
            </a>
        </div>
        <div>
            <a className={`iconLinkedin circleLink`}
               href="https://www.linkedin.com/company/ambiometrics" target="_blank">
                <FontAwesomeIcon icon={faLinkedinIn}/>
            </a>
        </div>
        <div>
            <a className={`iconInstagram circleLink`}
               href="https://www.instagram.com/ambiometrics" target="_blank">
                <FontAwesomeIcon icon={faInstagram}/>
            </a>
        </div>
    </div>;
}