import {Carousel} from "react-responsive-carousel";
import backgroundImage from "../assets/images/logo_119x119_contain.png";
import backgroundImage2 from "../assets/images/logo_119x119_contain.png";
import backgroundImage3 from "../assets/images/logo_119x119_contain.png";
import style from "./MainCarousel.module.css";

export function MainCarousel() {
    return <div style={{marginBottom: "5em"}}>
        <Carousel autoPlay showThumbs={false} showStatus={false} preventMovementUntilSwipeScrollTolerance={true} and swipeScrollTolerance={50} infiniteLoop={true}>
            <div style={{height: "100%"}}>
                <div className={style.section}
                     style={{backgroundImage: `url(${backgroundImage})`}}>
                    <div className="section-container">
                        Visibilizamos el IMPACTO AMBIENTAL de la acción humana analizando la MICRO-BIOSFERA
                    </div>
                </div>
            </div>
            <div style={{height: "100%"}}>
                <div className={style.section}
                     style={{backgroundImage: `url(${backgroundImage2})`}}>
                    <div className="section-container">
                        Utilizamos la actividad de los micro-organismos como KPI ambientales
                    </div>
                </div>
            </div>
            <div style={{height: "100%"}}>
                <div className={style.section}
                     style={{backgroundImage: `url(${backgroundImage3})`}}>
                    <div className="section-container">
                      Ambientes saludables, personas saludables
                    </div>
                </div>
            </div>
        </Carousel>
    </div>;
}