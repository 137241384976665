import style from './Elements.module.css';

function FancyBarSquare({color}) {
    return <div style={{
        width: "25%",
        height: "100%",
        backgroundColor: color}
    }></div>
}

const ambiometricsColor = {
    darkBlue: "#002a39",
    mossGreen: "#53917e",
    yellow: "#ffbc42",
    greyBlue: "#526b7a",
    lightBlue: "#c4f5fc"
};

export function BigTitleSub({text}) {
    return <div className={style.bigTitleSub}

    style={{ color: ambiometricsColor.greyBlue}}>
        {text}
    </div>
}

export function BigTitle({text}) {
    return <div className={style.bigTitle}
                style={{
                    color: ambiometricsColor.greyBlue,
                }}>
        {text}
    </div>
}

export function FancyBar({width = "50%", height = "20px"}) {

    return <div style={{
        display: "flex",
        width: width,
        height: height
    }}>
        <FancyBarSquare color={ambiometricsColor.darkBlue}/>
        <FancyBarSquare color={ambiometricsColor.mossGreen} />
        <FancyBarSquare color={ambiometricsColor.yellow} />
        <FancyBarSquare color={ambiometricsColor.greyBlue} />
        <FancyBarSquare color={ambiometricsColor.lightBlue} />

    </div>
}

export function Sustainability() {
    return <div className="section-container container-padding">
        <BigTitle text={"Sustentabilidad"}/>
        <BigTitleSub text={"sososos"}/>
        <FancyBar/>
    </div>;
}