import logo from "../assets/images/logo_119x119_contain.png";
import styles from "./Header.module.css";
import style from "./InfoContact.module.css";
import {email, telephoneNumber} from "../assets/constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faPhoneAlt} from "@fortawesome/free-solid-svg-icons";
import {TeamMemberBox} from "./TeamMemberBox";
import {BigTitle, BigTitleSub, FancyBar} from "./Sustainability";

export function TeamSection() {
    return <div className="section-container container-padding">
        <div class={style.section}>
            <BigTitleSub text={"Nuestro"}/>
            <BigTitle text={"Equipo"}/>
            <FancyBar/>
            <TeamMemberBox
                img={require ("../assets/images/portraits/portrait_marcela.jpg")}
                name={"Marcela Montoya Cárcamo"}
                education={"Mg. en Ciencias mención Genética"}
                role={<>
                    <div>Procesos experimentales</div>
                    <div>Logistica y muestreo</div>

                </>}
                />
            <TeamMemberBox
                img={require ("../assets/images/portraits/portrait_salva.jpg")}
                name={"Salvador Ramírez Flandes"}
                education={"Dr. en Ingeniería de Sistemas Complejos"}
                role={<>
                    <div>Análisis bioinformático</div>
                    <div>Inteligencia artificial</div>
                </>}/>
            <TeamMemberBox
                img={require ("../assets/images/portraits/portrait_edwin.jpg")}
                name={"Edwin Rodríguez León"}
                education={"Mg. en Innovación y Emprendimiento Tecnológico"}
                role={<>
                    <div>Desarrollo de negocios</div>
                    <div>Estrategia de crecimiento</div>
                </>}
            />

        </div>
    </div>;
}