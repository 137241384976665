import style from "./InfoContact.module.css";
import {email, telephoneNumber} from "../assets/constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faPhoneAlt} from "@fortawesome/free-solid-svg-icons";

export function TeamMemberBox({img, name, education, role, link}) {
    return <div className="section-container container-padding">
        <img src={img} width={300} height={300}/>
            <div>{name}</div>
            <div>{education}</div>
            <div>{role}</div>
    </div>;
}