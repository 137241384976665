import './App.css';
import {Header} from "./component/Header";
import {SocialButtons} from "./component/SocialButtons";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {MainCarousel} from "./component/MainCarousel";
import logo from "./assets/images/logo_119x119_contain.png";
import {TeamSection} from "./component/TeamSection";
import {Sustainability} from "./component/Sustainability";

function App() {
    const currentYear = new Date().getFullYear();
    return (
        <div>
            <Header/>
            <MainCarousel/>
            <TeamSection/>
            <Sustainability/>


            <SocialButtons/>
            <footer>
                <div className="section-container container-padding" style={{textAlign: "center", marginTop: "3em", marginBottom: "3em"}}>
                    <img src={logo}/>
                    <div className="footer-text">© {currentYear} - Ambiometrics Spa</div>
                </div>
            </footer>
        </div>
    );
}

export default App;
